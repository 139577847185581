<template>
    <span
        ><slot :load="load" :response="response" :loading="loading"></slot
    ></span>
</template>
<script>
import { StorageService } from '../services/storage';
const basePath = process.env.VUE_APP_PUBLIC_URL  || "/";
const localStore = StorageService;
export default {
    props: {
        loadOnMount: {
            type: Boolean,
            default: true,
        },
        apiPath: "",
        value: "",
        firstRecord: false, // should return single object from the response array
        queryParams: {
            type: Object,
            default: () => ({}),
        },
    },
    data: function () {
        return {
            response: [],
            loading: false,
        };
    },
    computed: {
        apiUrl: function () {
            let qs = this.$utils.serializeQuery(this.queryParams);
            let url = this.apiPath;
            if (qs) {
                url = url + "?" + qs;
            }
            return url;
        },
    },
    methods: {
        load: function () {
            if (this.apiUrl) {
                this.loading = true;
                this.$api.get(this.apiUrl).then(
                    (response) => {
                        this.loading = false;
                        let result = response.data;
                        if (this.firstRecord && result.length) {
                            this.response = result[0];
                        } else {
                            this.response = result;
                        }
                        this.$emit("loaded", this.response);
                    },
                    (error) => {
                        this.loading = false;
                        if (this.firstRecord) {
                            this.response = {};
                        } else {
                            this.response = [];
                        }

                        // Manejo de errores
                        const xhrRequest = error?.request;
                        if (xhrRequest?.status === 401) {
                            // Token podría haber expirado
                            // Cerrar sesión y redirigir a la página de inicio de sesión
                            localStore.removeLoginData(); // Eliminar datos de sesión
                            location.href = basePath; // Redirigir a la página de inicio de sesión
                        } else {
                            // Mostrar otros errores
                            store.showPageErrors(errorMsgs);
                        }
                    }
                );
            }
        },
    },
    watch: {
        apiUrl: function () {
            this.load();
        },
    },
    mounted: function () {
        if (this.loadOnMount) {
            this.load();
        }
    },
};
</script>