export default {
	startsWith: 'Empieza con',
	contains: 'Contiene',
	notContains: 'No incluye',
	endsWith: 'Termina con',
	equals: 'igual',
	notEquals: 'no es igual',
	noFilter: 'Sin filtro',
	lt: 'Menos que',
	lte: 'Menos que o igual a',
	gt: 'Mayor que',
	gte: 'Mayor qué o igual a',
	dateIs: 'Fecha es',
	dateIsNot: 'Fecha no es',
	dateBefore: 'La fecha es antes',
	dateAfter: 'La fecha es despues',
	clear: 'Limpiar',
	apply: 'Aplicar',
	matchAll: 'Coincidir con todo',
	matchAny: 'Coincide con cualquiera',
	addRule: 'Añadir Regla',
	removeRule: 'Quitar Regla',
	accept: 'Sí',
	reject: 'No',
	choose: 'Escoger',
	upload: 'Subir',
	cancel: 'Cancelar',
	dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
	dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
	dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
	monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
	monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
	today: 'Hoy',
	weekHeader: 'Semana',
	firstDayOfWeek: 1,
	dateFormat: 'dd-mm-yy',
	weak: 'Débil',
	medium: 'Mediano',
	strong: 'Fuerte',
	vendors: 'Proveedores',
	passwordPrompt: 'Introduce una contraseña',
	emptyFilterMessage: 'No se han encontrado resultados',
	emptyMessage: 'Sin opciones disponibles',
	uploadedFiles: 'Archivos subidos',
	uploadFailed: 'Fallo al subir el archivo',
	maxFileLimit: 'Limite de archivos de ${limit} excedido!',
	aria: {
		trueLabel: "Verdadero",
		falseLabel: "Falso",
		nullLabel: "No seleccionado",
		pageLabel: "PûÀgina",
		firstPageLabel: "Primera PûÀgina",
		lastPageLabel: "û?ltima PûÀgina",
		nextPageLabel: "PûÀgina Siguiente",
		previousPageLabel: "PûÀgina Anterior",
		selectLabel: "Seleccione",
		unselectLabel: "Deseleccionar",
		expandLabel: "Expandir",
		collapseLabel: "Contraer"
	},
	validations: {
		required: "El valor es requerido.",
		undefined: "El valor es requerido.",
		email: "El valor no es una dirección de correo electrónico válida",
		minLength: "Este campo debe tener al menos ${min} caracteres",
		maxLength: "La longitud máxima permitida es ${max}",
		minValue: "El valor mínimo permitido es ${min}",
		maxValue: "El valor máximo permitido es ${max}",
		numeric: "El valor debe ser numérico",
		ipAddress: "El valor no es una dirección IP válida",
		sameAs: "El valor debe ser igual al valor de ${otherName}",
	},
	company: "Compañía",
	company_name: "Nombre de la compañía",
	visitant: "Visitante",
	visitant_name: "Nombre del visitante",
	visitant_email: "Correo electrónico del visitante",
	visitant_phone: "Teléfono del visitante",
	phone: "Teléfono",
	subject: "Asunto",
	visit_to: "Visita a",
	select_to: "Seleccionar a quién visitará",
	date_to_visit: "Fecha de visita",
	time_to_visit: "Hora de visita",
	identification: "Identificación",
	save: "Guardar",
	info_not_permitted: "El acceso es únicamente para visita, no se permite trabajar dentro de la planta con este permiso de acceso.",
	captcha_advisement: "Verifique que no es un robot. Los robots no pueden acceder a la planta.",
	captcha_expiration_advisement: "¡Despues de validar el captcha cuenta con 5 minutos para realizar la solicitud de visita!.",
	show_security_rules: "Mostrar reglas de seguridad",
	employees: "Empleados",
	visitors: "Visitantes",
	suaMenu: "SUA",
	sua: "Sistema Único de Autodeterminación (SUA)",
	addNewSua: "Agregar nuevo SUA",
	search: "Buscar",
	vendor: "Proveedor",
	file_path: "Archivo",
	waitingResponse: "Esperando respuesta",
	validated: "Validado",
	rejected: "Rechazado",
	comment: "Comentario",
	status: "Estado",
	UnderReview: "En revisión",
	Validated: "Validado",
	Rejected: "Rechazado",
	noRecordFound: "No se encontraron registros",
	deleteSelected: "Eliminar seleccionados",
	records: "Registros",
	of: "de",
	viewDoc: "Ver documento",
	editFiles: "Editar archivos",
	validSua: "Validar SUA",
	rejectSua: "Rechazar SUA",
	rejectSuaTitle: "Rechazar SUA",
	rejectSuaMessage: "¿Esté seguro de rechazar el SUA?",
	acceptSua: "Aceptar SUA",
	acceptSuaTitle: "Aceptar SUA",
	acceptSuaMessage: "¿Esté seguro de aceptar el SUA?",
	accept: "Aceptar",
	enterComment: "Introducir comentario",
	cancel: "Cancelar",
	confirm: "Confirmar",
	deleteRecord: "Eliminar registro",
	promptDeleteRecord: "¿Esté seguro de eliminar el registro?",
	recordDeletedSuccessfully: "Registro eliminado correctamente",
	Fileviewer: "Vista previa de archivos",
	view: "Ver",
	edit: "Editar",
	delete: "Eliminar",
	msgAfterReject: "Rechazado con éxito",
	msgAfterAccept: "Aceptado con éxito",
	chooseFilesOrDropFilesHere: "Escoger archivos o soltarlos aquí",
	submit: "Enviar",
	errorUploadingFile: "Error al subir el archivo",
	uploadFailed: "Fallo al subir el archivo",
	uploadedFiles: "Archivos subidos",
	editSua: "Editar SUA",
	update: "Actualizar",
	loading: "Cargando",
	Valid: "Validado",
	enterExpiration: "Introducir fecha de expiración",
	expiration: "Expiración",
	Expired: "Expirado",
	home: "Inicio",
	rejectAccessRequestTitle: "Rechazar solicitud de acceso",
	accessrequests: "Solicitudes de acceso",
	newAccessRequest: "Nueva solicitud de acceso",
	date: "Fecha",
	visit_time: "Hora de visita",
	identification: "Identificación",
	visitor: "Visitante",
	requester: "Solicitante",
	subject: "Asunto",
	documentationQ: "¿Documentación?",
	noRequireRevision: "No requiere\nrevisión",
	documentationCorrect: "Documentación\ncorrecta",
	requireRevision: "Revisión\npara trabajos",
	acceptedQ: "¿Aceptado?",
	yes: "Sí",
	no: "No",
	Accesses: "Accesos",
	accessLog: "Bitácora de acceso",
	accessvip: "Accesos VIP",
	accesslogvip: "Bitácora de acceso VIP",
	hello: "Hola",
	allRightsReserved: "Todos los derechos reservados",
	Documentación: "Documentación",
	Validación: "Validación",
	Rechazado: "Rechazado",
	Aceptado: "Aceptado",
	Completado: "Completado",
	Invitado: "Invitado",
	acceptvisit: "Aceptar visita",
	requestCannotBeCompleted: "La solicitud no puede ser completada",
	myaccount: "Mi cuenta",
	logout: "Cerrar sesión",
	expires: "Expira",
	scheduledVisitDate: "Fecha de visita programada",
	suaDocumentation: "Documentación SUA",
	employee: "Empleado",
	email: "Correo electrónico",
	phone: "Teléfono",
	priority: "Prioridad",
	select_a_value: "Seleccione un valor",
	enterSubject: "Escribir asunto",
	dragAndDropHere: "Arrastre y suelte el archivo aquí",
	accessCredential: "Credencial de Acceso",
	rfc: "RFC",
	nss: "NSS",
	enterRFC: "Introducir RFC",
	enterNSS: "Introducir NSS",
	addEmployee: "Agregar empleado",
	employeeName: "Nombre del empleado",
	enterEmployeeName: "Introducir nombre del empleado",
	enterEmail: "Introducir correo electrónico",
	nss: "NSS",
	privacyNotice: "Aviso de privacidad",
	securityRules: "Normas de seguridad",
	youMustAcceptPrivacyNotice: "Debe aceptar y leer detalladamente el aviso de privacidad para estar de acuerdo con las normas de información así como el uso de su información personal.",
	youMustAcceptSecurityRules: "Debe aceptar y leer detalladamente las normas de seguridad de la planta.",
	acceptAndContinue: "Aceptar y continuar",
}