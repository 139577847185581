export default {
  startsWith: 'Starts with',
  contains: 'Contains',
  notContains: 'Not contains',
  endsWith: 'Ends with',
  equals: 'Equals',
  notEquals: 'Not equals',
  noFilter: 'No Filter',
  lt: 'Less than',
  lte: 'Less than or equal to',
  gt: 'Greater than',
  gte: 'Greater than or equal to',
  dateIs: 'Date is',
  dateIsNot: 'Date is not',
  dateBefore: 'Date is before',
  dateAfter: 'Date is after',
  custom: 'Custom',
  clear: 'Clear',
  apply: 'Apply',
  vendors: 'Vendors',
  matchAll: 'Match All',
  matchAny: 'Match Any',
  addRule: 'Add Rule',
  removeRule: 'Remove Rule',
  accept: 'Yes',
  reject: 'No',
  choose: 'Choose',
  upload: 'Upload',
  cancel: 'Cancel',
  dayNames: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  monthNamesShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  today: 'Today',
  weekHeader: 'Wk',
  firstDayOfWeek: 0,
  dateFormat: 'yyyy-mm-dd',
  weak: 'Weak',
  medium: 'Medium',
  strong: 'Strong',
  passwordPrompt: 'Enter a password',
  emptyFilterMessage: 'No results found',
  emptyMessage: 'No available options',
  uploadedFiles: 'Uploaded files',
  uploadFailed: 'Upload Failed',
  maxFileLimit: 'Max file limit of ${limit} exceeded!',
  aria: {
    trueLabel: 'True',
    falseLabel: 'False',
    nullLabel: 'Not Selected',
    pageLabel: 'Page',
    firstPageLabel: 'First Page',
    lastPageLabel: 'Last Page',
    nextPageLabel: 'Next Page',
    previousPageLabel: 'Previous Page',
    selectLabel: 'Select',
    unselectLabel: 'Unselect',
    expandLabel: 'Expand',
    collapseLabel: 'Collapse',
  },
  validations: {
    required: 'Value is required.',
    undefined: 'Value is required.',
    email: 'Value is not a valid email address',
    minLength: 'This field should be at least ${min} characters long',
    maxLength: 'The maximum length allowed is ${max}',
    minValue: 'The minimum value allowed is ${min}',
    maxValue: 'The maximum value allowed is ${max}',
    numeric: 'Value must be numeric',
    ipAddress: 'The value is not a valid IP address',
    sameAs: 'The value must be equal to the ${otherName} value',
  },
  company: 'Company',
  company_name: 'Company Name',
  visitant: 'Visitor',
  visitant_name: 'Visitor Name',
  visitant_email: 'Visitor Email',
  visitant_phone: 'Visitor Phone',
  phone: 'Phone',
  subject: 'Subject',
  visit_to: 'Visit To',
  select_to: 'Select Who to Visit',
  date_to_visit: 'Date of Visit',
  time_to_visit: 'Time of Visit',
  identification: 'Identification',
  save: 'Save',
  info_not_permitted:
    'Access is for visit only, working inside the plant is not allowed with this access permission.',
  show_security_rules: 'Show security rules',
  visitors: 'Visitors',
  employees: 'Employees',
  captcha_advisement: 'Verify that you are not a robot. Robots cannot access the plant.',
  captcha_expiration_advisement: 'After validating the captcha you have 5 minutes to make the request for visit.',
  suaMenu: "SUA",
  sua: "Unique Self-Determination System (SUA)",
  addNewSua: "Add New SUA",
  search: "Search",
  vendor: "Vendor",
  file_path: "File",
  waitingResponse: "Waiting for Response",
  validated: "Validated",
  rejected: "Rejected",
  comment: "Comment",
  status: "Status",
  UnderReview: "Under Review",
  Validated: "Validated",
  Rejected: "Rejected",
  noRecordFound: "No records found",
  deleteSelected: "Delete Selected",
  records: "Records",
  of: "of",
  viewDoc: "View Document",
  editFiles: "Edit Files",
  validSua: "Validate SUA",
  rejectSua: "Reject SUA",
  rejectSuaTitle: "Reject SUA",
  rejectSuaMessage: "Are you sure you want to reject the SUA?",
  acceptSua: "Accept SUA",
  acceptSuaTitle: "Accept SUA",
  acceptSuaMessage: "Are you sure you want to accept the SUA?",
  accept: "Accept",
  enterComment: "Enter Comment",
  cancel: "Cancel",
  confirm: "Confirm",
  deleteRecord: "Delete Record",
  promptDeleteRecord: "Are you sure you want to delete the record?",
  recordDeletedSuccessfully: "Record deleted successfully",
  Fileviewer: "File Preview",
  view: "View",
  edit: "Edit",
  delete: "Delete",
  msgAfterReject: "Successfully Rejected",
  msgAfterAccept: "Successfully Accepted",
  chooseFilesOrDropFilesHere: "Choose Files or Drop Files Here",
  submit: "Submit",
  errorUploadingFile: "Error Uploading File",
  uploadFailed: "Upload Failed",
  chooseFilesOrDropFilesHere: "Choose Files or Drop Files Here",
	submit: "Submit",
	errorUploadingFile: "Error uploading file",
	uploadFailed: "Upload Failed",
	uploadedFiles: "Uploaded Files",
  editSua: "Edit SUA",
  update: "Update",
  loading: "Loading",
  enterExpiration: "Enter Expiration Date",
  expiration: "Expiration",
  Expired: "Expired",
  home: "Home",
  rejectAccessRequestTitle: "Reject Access Request",
  accessrequests: "Access Requests",
  newAccessRequest: "New Access Request",
  date: "Date",
  visit_time: "Visit Time",
  identification: "Identification",
  visitor: "Visitor",
  requester: "Requester",
  subject: "Subject",
  documentationQ: "Documentation?",
  noRequireRevision: "No revision\nrequired",
  documentationCorrect: "Documentation\ncorrect",
  requireRevision: "Revision\nrequired",
  acceptedQ: "Accepted?",
  yes: "Yes",
  no: "No",
  Accesses: "Accesses",
  accessLog: "Access Log",
  accessvip: "Access VIP",
  accesslogvip: "Access Log VIP",
  hello: "Hello",
  allRightsReserved: "All rights reserved",
  Documentación: "Documentation",
	Validación: "Validation",
	Rechazado: "Rejected",
	Aceptado: "Acepted",
	Completado: "Completed",
	Invitado: "Guest",
  acceptvisit: "Accept Visit",
  requestCannotBeCompleted: "The request cannot be completed",
  myaccount: "My Account",
  logout: "Logout",
  expires: "Expires",
  scheduledVisitDate: "Scheduled Visit Date",
  suaDocumentation: "SUA Documentation",
  employee: "Employee",
  email: "Email",
  phone: "Phone",
  priority: "Priority",
  select_a_value: "Select a value",
  enterSubject: "Enter Subject",
  dragAndDropHere: "Drag and Drop File Here",
  accessCredential: "Access Credential",
  rfc: "RFC",
  nss: "NSS",
  enterRFC: "Enter RFC",
  enterNSS: "Enter NSS",
  addEmployee: "Add Employee",
  employeeName: "Employee Name",
  enterEmployeeName: "Enter Employee Name",
  enterEmail: "Enter Email",
  nss: "SSN",
  privacyNotice: "Privacy Notice",
  securityRules: "Security Rules",
  youMustAcceptPrivacyNotice: "You must accept and read carefully the privacy notice to be in agreement with the security rules as well as the use of your personal information.",
  youMustAcceptSecurityRules: "You must accept and read carefully the security rules.",
};