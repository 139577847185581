import { $t } from './services/i18n.js';

export const AppMenus = {
  navbarTopRight: [],
  navbarTopLeft: [],
  navbarSideLeft: [
    {
      to: '/home',
      label: $t('home'),
      icon: 'pi pi-chart-bar text-primary',
      iconcolor: '',
      target: '',
    },
    {
      label: $t('Accesses'),
      icon: 'pi pi-sign-in text-primary',
      items: [
        {
          to: '/accessrequests',
          label: $t('accessrequests'),
          icon: 'pi pi-id-card text-primary',
        },
        {
          to: '/accesslog',
          label: $t('accessLog'),
          icon: 'pi pi-book text-primary',
        },
        {
          to: '/accessvip',
          label: $t('accessvip'),
          icon: 'pi pi-star text-primary',
        },
        {
          to: '/accesslogvip',
          label: $t('accesslogvip'),
          icon: 'pi pi-book text-primary',
        },
       
      ],
    },
    {
      label: 'Contratistas',
      icon: 'pi pi-building text-primary',
      items: [
        {
          to: '/vendors/vendors.type/2',
          label: 'Proveedores',
          icon: 'pi pi-building text-primary',
        },
       
        {
          to: '/bitacora',
          label: 'Bitácora de control',
          icon: 'pi pi-book text-primary',
        },
        {
          to: '/employees/vendors.type/2',
          label: 'Empleados',
          icon: 'pi pi-users text-primary',
        },
      ],
    },
    {
      label: 'Residentes',
      icon: 'pi pi-building text-primary',
      items: [
        {
          to: '/vendors/vendors.type/1',
          label: 'Proveedores',
          icon: 'pi pi-building text-primary',
        },
       
        {
          to: '/bitacoraresident',
          label: 'Bitácora de control',
          icon: 'pi pi-book text-primary',
        },
        {
          to: '/employees/vendors.type/1',
          label: 'Empleados',
          icon: 'pi pi-users text-primary',
        },
        {
          to: '/sua',
          label: $t('suaMenu'),
          icon: 'pi pi-verified text-primary',
          iconcolor: '',
          target: '',
        },
      ],
    },
    {
      label: 'Recibo',
      icon: 'pi pi-truck text-primary',
      items: [
        {
          to: '/carrierrequests',
          label: 'Solicitud de carrier',
          icon: 'pi pi-id-card text-primary',
        },
        {
          to: '/ramps-dashboards',
          label: 'Andenes Dashboard',
          icon: 'pi pi-chart-pie text-primary',
        },
        {
          to: '/carrier_accesslog',
          label: 'Bitácora de carrier',
          icon: 'pi pi-book text-primary',
        },
        {
          to: '/ramps-screen',
          label: 'Pantalla Andenes',
          icon: 'pi pi-desktop text-primary',
        },
      ],
    },
    {
      label: 'Administración',
      icon: 'pi pi-cog text-primary',
      items: [
        {
          to: '/users',
          label: 'Usuarios',
          icon: 'pi pi-users',
        },
        {
          to: '/userdepts',
          label: 'Departamentos',
          icon: 'pi pi-sitemap',
        },
        {
          to: '/vendorslevels',
          label: 'Categorías de proveedor',
          icon: 'pi pi-bookmark',
        },
        {
          to: '/accessrequestpriorities',
          label: 'Catálogo Prioridades',
          icon: 'pi pi-sort-alt',
        },
        {
          to: '/accessrequeststatus',
          label: 'Estatus de Solicitudes',
          icon: 'pi pi-check-circle',
        },
        {
          to: '/userstatus',
          label: 'Estatus de Usuario',
          icon: 'pi pi-check-circle',
        },
        {
          to: '/vendorsstatus',
          label: 'Estatus de proveedor',
          icon: 'pi pi-check-circle',
        },
        {
          to: '/repsestatus',
          label: 'Estatus de REPSE',
          icon: 'pi pi-check-circle',
        },
        {
          to: '/permissions',
          label: 'Permisos',
          icon: 'pi pi-th-large text-primary',
        },
        {
          to: '/roles',
          label: 'Roles',
          icon: 'pi pi-th-large text-primary',
        },
      ],
    },
  ],
  exportFormats: {
    print: {
      label: 'Print',
      icon: 'pi pi-print',
      type: 'print',
      ext: 'print',
    },
    pdf: {
      label: 'Pdf',
      icon: 'pi pi-file-pdf',
      type: 'pdf',
      ext: 'pdf',
    },
    excel: {
      label: 'Excel',
      icon: 'pi pi-file-excel',
      type: 'excel',
      ext: 'xlsx',
    },
    csv: {
      label: 'Csv',
      icon: 'pi pi-table',
      type: 'csv',
      ext: 'csv',
    },
  },
};
