<template>
    <div class="language-switch" @click="changeLanguage">
        <div class="switch-container" :class="{ 'bg-blue': currentLanguage === 'en-US' }">
           
            <img :src="usFlag" class="flag-icon flag-left" />
            <img :src="mxFlag" class="flag-icon flag-right" />
            <div class="slider" :class="{ 'move-right': currentLanguage === 'en-US' }"></div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { i18n } from 'src/services/i18n';
import { StorageService } from 'src/services/storage.js';

const mxFlag = 'images/mexico-flag.svg';
const usFlag = 'images/us-flag.svg';
const currentLanguage = ref(StorageService.getLocale() || i18n.locale.value);

const changeLanguage = () => {
    currentLanguage.value = currentLanguage.value === 'es' ? 'en-US' : 'es';
    i18n.setLocale(currentLanguage.value);
    StorageService.setLocale(currentLanguage.value);
};

// Verificar el idioma del navegador en el primer montaje
onMounted(() => {
    if (!StorageService.getLocale()) {
        const browserLocale = navigator.language.startsWith('es') ? 'es' : 'en-US';
        i18n.setLocale(browserLocale);
        currentLanguage.value = browserLocale;
    }
});

// Sincronizar idioma en cambios externos
watch(() => i18n.locale.value, (newLocale) => {
    currentLanguage.value = newLocale;
});
</script>

<style scoped>
.language-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* Contenedor del switch */
.switch-container {
    position: relative;
    width: 60px;
    height: 30px;
    background-color: #d1d1d1;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 4px;
    transition: background 0.3s ease-in-out;
}

/* Cambia de color al cambiar el idioma */
.bg-blue {
    background-color: #7598d1;
}

/* Slider (botón) */
.slider {
    position: absolute;
    width: 28px;
    height: 28px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
}

/* Mueve el slider a la derecha */
.move-right {
    transform: translateX(30px);
}

/* Bandera izquierda (México) */
.flag-left {
    position: absolute;
    left: 5px;
}

/* Bandera derecha (EEUU) */
.flag-right {
    position: absolute;
    right: 5px;
}

/* Imágenes de banderas */
.flag-icon {
    width: 18px;
    height: 18px;
}
</style>
